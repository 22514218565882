import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import Footer from '../components/layout/Footer'

const GlobalStyle = createGlobalStyle`
  * {font-family: Montserrat ,open-sans,sans-serif;}
  html{
    height: 100%;
    scroll-behavior: smooth;
  }
  body {
    background-color: ${props => props.theme.bgMain};;
    height: 100%;
    padding: 0px;
    margin: 0px;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  a { cursor: pointer; text-decoration:none; font-weight: 800; font-size: 1.4rem;  }
  h1 {line-height: 1.4; font-size: 42px; color: ${props =>
    props.theme.primary};}
  h2 {line-height: 1.4; color: ${props => props.theme.primary};}
  h3 {line-height: 1.4; color: ${props =>
    props.theme.secondary}; font-size: 1.8rem}
  button { cursor: pointer;}
  
`

const theme = {
  // Main
  bgMain: 'white',
  bgSecondary: 'lightgreen',
  bgContainer: 'white',
  highlight: '#512c62',
  //   Värit
  primary: '#750292',
  secondary: '#008188',
  // Suhteet
  sidebarRatio: '80% 20%',
  // Header asetukset
  headerBg: 'red',
  // Menu asetukset
  menuBg: '#4087f3',
  menuText: '#124858',
  menuItemHover: '#f6c89f',
  mobileMenuBg: '#dfecff',
  mobileMenuText: '#027192',
  mobileMenuTextHover: '#4dd599',
  // Sidebar asetukset
  // Button
  actionButton: 'orange',
  actionButtonText: 'white',
  actionButtonHover: '#c70d3a',
  infoButton: '#ff026b',
  infoButtonText: 'white',
  infoButtonHover: '#f587b5',
}

const GlobalStyles = props => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {props.children}
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default GlobalStyles
