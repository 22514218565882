import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import { H3 } from '../layout/headers/Headers'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const Centered = styled.div`
  text-align: center;
`
const Text = styled.div`
  font-size: 1.1rem;
`

const ButtonContainer = props => {
  const data = props.data
  const currentTitle = props.currentTitle
  const buttons = data.map(n =>
    n.node.frontmatter.title !== currentTitle ? (
      <Button
        external={false}
        url={n.node.fields.newPath}
        key={n.node.frontmatter.title}
      >
        <Text>{n.node.frontmatter.title}</Text>
      </Button>
    ) : (
      ''
    ),
  )
  return (
    <Centered>
      <H3>Tutustu myös näihin</H3>
      <Container>{buttons}</Container>
    </Centered>
  )
}

export default ButtonContainer
