import { useStaticQuery, graphql } from 'gatsby'
export const useLoansJson = (type, loanSum) => {
  const { allLoansJson } = useStaticQuery(
    graphql`
      query useLoansJson {
        allLoansJson(sort: { fields: interes }) {
          edges {
            node {
              age
              benefits
              freeLoan
              id
              link
              maxLoan
              filter
              maxLoanTime
              minLoan
              minLoanTime
              minPayment
              name
              openFee
              requests
              review
              type
              interes
            }
          }
        }
      }
    `,
  )

  let loans = allLoansJson.edges

  if (type) loans = loans.filter(n => n.node.type === type)
  if (loanSum) loans = loans.filter(n => n.node.maxLoan > loanSum)
  return loans
}
