import React from 'react'
import styled from 'styled-components'
import LogoImage from '../images/LogoImage'
import { device } from '../../utils/responsive'
import { useHidden } from '../../hooks/useHidden'
import { Link } from 'gatsby'
import { useMainArticles } from '../../hooks/useMainArticles'

const DesktopMenu = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0.5rem;
  max-height: 250px;
  background-color: white;
  @media ${device.mobileL} {
    justify-content: space-between;
  }
`
const MenuItem = styled.p`
  height: 100%;
  margin: 1rem 0.5rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  border-bottom: 1px solid transparent;
  color: ${props => props.theme.menuText};
  :hover {
    border-bottom: 1px solid ${props => props.theme.menuItemHover};
  }
  :active {
    border-bottom: 1px solid ${props => props.theme.menuItemHover};
  }
  @media ${device.mobileL} {
    display: none;
  }
`

const Hamburger = styled.div`
  display: none;
  height: 40px;
  width: 52px;
  padding: 7px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-around;
  @media ${device.mobileL} {
    display: flex;
  }
`

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  visibility: ${props => props.visible};
  width: 100%;
  height: 100%;
  -webkit-transform: ${props => props.translate};
  -ms-transform: ${props => props.translate};
  transform: ${props => props.translate};
  -moz-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  -webkit-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  background-color: ${props => props.theme.mobileMenuBg};
  opacity: 0.99;
  z-index: 200;
`

const MobileMenuItem = styled.p`
  color: ${props => props.theme.mobileMenuText};
  font-weight: 600;
  padding: 0.6rem;
  margin: 0.1rem 0rem;
  border-left: 4px solid lightblue;
  box-shadow: 0px 2px 3px 0px #3333332b;
  background-color: white;
`

const HamburgerLine = styled.div`
  width: 42px;
  height: 4px;
  background-color: ${props => props.theme.primary};
`

const Logo = styled(LogoImage)`
  font-size: 30px;
  font-weight: 800;
  padding: 2rem;
`

const MobileLogo = styled(Logo)`
  padding: 1.5rem;
  background-color: #4087f3;
`

const CloseButton = styled.div`
  position: fixed;
  right: 15px;
  top: 10px;
  font-size: 50px;
  color: ${props => props.theme.actionButton};
  z-index: 300;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  font-size: 60px;
`

const LogoArea = styled.div`
  background-color: #4087f3;
  padding: 1rem;
`

const Menubar = () => {
  const desktopMode = useHidden(true)
  const menuItems = useMainArticles()
  const desktopItems = menuItems.map(n => (
    <Link to={n.node.fields.newPath} key={n.node.frontmatter.title}>
      <MenuItem>{n.node.frontmatter.urlTitle}</MenuItem>
    </Link>
  ))
  const mobileItems = menuItems.map(n => (
    <Link to={n.node.fields.newPath} key={n.node.frontmatter.title}>
      <MobileMenuItem>{n.node.frontmatter.urlTitle}</MobileMenuItem>
    </Link>
  ))

  return (
    <>
      <MobileMenu
        translate={desktopMode.show ? 'translateY(-100%)' : 'translateY(0)'}
      >
        <LogoArea>
          <Link to={'/'}>
            <MobileLogo>LOGO</MobileLogo>
          </Link>
        </LogoArea>
        <CloseButton onClick={() => desktopMode.setVisible()}>x</CloseButton>
        {mobileItems}
      </MobileMenu>

      <DesktopMenu>
        {desktopMode.show ? (
          <>
            <Link to={'/'}>
              <Logo>LOGO</Logo>
            </Link>
            {desktopItems}
            <Hamburger onClick={() => desktopMode.setHidden()}>
              <HamburgerLine></HamburgerLine>
              <HamburgerLine></HamburgerLine>
              <HamburgerLine></HamburgerLine>
            </Hamburger>
          </>
        ) : (
          ''
        )}
      </DesktopMenu>
    </>
  )
}

export default Menubar
