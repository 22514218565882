import React from 'react'
import styled from 'styled-components'
import { device } from '../../../utils/responsive'

const Header1 = styled.h1`
  color: ${props => props.theme.primary};
  text-align: center;
  padding: 2rem;
  opacity: 1;

  @media ${device.mobileL} {
    font-size: 35px;
    padding: 0.2rem;
  }
`
const Header2 = styled.h2`
  color: ${props => props.theme.secondary};
  padding: 1.5rem;
  @media ${device.mobileL} {
    padding: 0.1rem;
    font-size: 30px;
  }
`

const Header3 = styled.h3`
  color: ${props => props.theme.secondary};
  padding: 1rem;
  @media ${device.mobileL} {
    font-size: 25px;
    padding: 0.1rem;
  }
`

export const H1 = props => <Header1>{props.children}</Header1>
export const H2 = props => <Header2>{props.children}</Header2>
export const H3 = props => <Header3>{props.children}</Header3>
