import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const ButtonLink = styled.div`
  padding: 1.2rem 1.9rem;
  vertical-align: top;
  text-overflow: ellipsis;
  border-radius: 20px;
  background-color: ${props =>
    props.action ? props.theme.actionButton : props.theme.infoButton};
  color: ${props =>
    props.action ? props.theme.actionButtonText : props.theme.infoButtonText};
  margin: 2rem 1rem;
  text-align: center;
  :hover {
    background-color: ${props =>
      props.action
        ? props.theme.actionButtonHover
        : props.theme.infoButtonHover};
  }
`
const AffiliateLink = styled(OutboundLink)``

const Button = props => {
  const external = props.external
  return external ? (
    <AffiliateLink
      href={props.url}
      rel="nofollow noopener noreferrer"
      target="_blank"
    >
      <ButtonLink>{props.children}</ButtonLink>
    </AffiliateLink>
  ) : (
    <Link to={props.url}>
      <ButtonLink>{props.children}</ButtonLink>
    </Link>
  )
}

export default Button
