import { useStaticQuery, graphql } from 'gatsby'
export const useMainArticles = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query useMainArticles {
        allMarkdownRemark(
          sort: { order: ASC, fields: frontmatter___priority }
          filter: { frontmatter: { type: { eq: "article" } } }
        ) {
          edges {
            node {
              excerpt
              fields {
                newPath
              }
              frontmatter {
                title
                img
                urlTitle
              }
            }
          }
        }
      }
    `,
  )
  return allMarkdownRemark.edges
}
