import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Image = styled(Img)`
  margin-top: 5px;
`

const LogoImage = ({ imgName }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      let image = data.allImageSharp.edges.find(
        edge => edge.node.fixed.originalName === `logo.png`,
      )
      if (!image) {
        image = data.allImageSharp.edges.find(
          edge => edge.node.fixed.originalName === `lainakuvadefault.jpg`,
        )
      }
      return <Image fixed={image.node.fixed} alt={imgName} />
    }}
  />
)
export default LogoImage
