import { useStaticQuery, graphql } from 'gatsby'
export const useLoanCompareMd = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query useLoanCompareMd {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "loancompare" } } }
          sort: { fields: frontmatter___loanSum }
        ) {
          edges {
            node {
              id
              excerpt
              fields {
                newPath
              }
              frontmatter {
                loanSum
                title
                type
              }
            }
          }
        }
      }
    `,
  )
  return allMarkdownRemark.edges
}
