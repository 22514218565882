import React from 'react'
import styled from 'styled-components'
import Config from '../../config/Config'
import { device } from '../../utils/responsive'

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 2rem;
  background-color: ${props => props.theme.secondary};
  color: white;
  @media ${device.mobileL} {
    flex-direction: column;
  }
`

const ContainerItem = styled.div`
  @media ${device.mobileL} {
    margin: 1rem;
  }
`

const Footer = () => {
  return (
    <Container>
      <ContainerItem>© Copyright 2019 {Config.url}</ContainerItem>
      <ContainerItem>Lisätietoa palvelusta</ContainerItem>
      <ContainerItem>Ota yhteyttä</ContainerItem>
    </Container>
  )
}

export default Footer
