import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import Config from '../../config/Config'

const getSchemaOrgJSONLD = ({
  isBlogPost,
  url,
  title,
  image,
  description,
  datePublished,
}) => {
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: Config.title,
    },
  ]

  return isBlogPost
    ? [
        ...schemaOrgJSONLD,
        {
          '@context': Config.url,
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': url,
                name: title,
                image,
              },
            },
          ],
        },
        {
          '@context': Config.url,
          '@type': 'BlogPosting',
          url,
          name: title,
          alternateName: Config.title,
          headline: title,
          image: {
            '@type': 'ImageObject',
            url: image,
          },
          description,
          author: {
            '@type': 'Person',
            name: 'Mikko',
          },
          publisher: {
            '@type': 'Organization',
            url: Config.url,
            logo: Config.logo,
            name: 'Mikko',
          },
          mainEntityOfPage: {
            '@type': 'WebSite',
            '@id': Config.url,
          },
          datePublished,
        },
      ]
    : schemaOrgJSONLD
}

const SEO = ({ postData, postImage, isBlogPost }) => {
  const postMeta = postData || {}

  const title = postMeta.newMetaTitle || Config.title
  const description =
    postMeta.newMetaDesc || postData.excerpt || Config.description
  const image = `${Config.url}${postImage}` || Config.image
  const slug = postMeta.newPath
  const url = slug ? `${Config.url}${postMeta.newPath}` : Config.url
  const datePublished = isBlogPost ? postMeta.date : false

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    isBlogPost,
    url,
    title,
    image,
    description,
    datePublished,
  })

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <html lang="fi" />
      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      {isBlogPost ? <meta property="og:type" content="article" /> : null}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={Config.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {/* Verications */}
      {/* <meta name="google-site-verification" content="K38mAuIaM1u2HenJYz-g_gL22snxHrQM7ur_s_qIik8" /> */}
      <meta
        name="ahrefs-site-verification"
        content="70c2df0767f58b1cb62a5efe748c22711d61e9f1d3d1bc64aa6e0f0f5e393873"
      />
    </Helmet>
  )
}

SEO.propTypes = {
  isBlogPost: PropTypes.bool,
  postData: PropTypes.shape({
    frontmatter: PropTypes.any,
    excerpt: PropTypes.any,
  }).isRequired,
  postImage: PropTypes.string,
}

SEO.defaultProps = {
  isBlogPost: false,
  postImage: null,
}

export default SEO
