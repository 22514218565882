import React from 'react'
import styled from 'styled-components'
import { useState } from 'react'
import { H3 } from '../layout/headers/Headers'
import Button from '../buttonComponents/Button'
import { useLoanCompareMd } from '../../hooks/useLoanCompareMd'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 40px;
  border: none;
  text-align: center;
  padding: 2rem;
`
const Select = styled.select`
  background-color: white;
  color: ${props => props.theme.secondary};
  padding: 0.5rem 2.5rem;
  text-align: center;
  max-width: 400px;
  font-size: 25px;
  border-radius: 10px;
  text-decoration: none;
  outline: none;
  font-weight: 800;
  margin: 1rem;
`

const Option = styled.option`
  background-color: ${props => props.theme.secondary};
  color: white;
`
const Desc = styled.label`
  color: white;
`

const Link = styled.a``

const DropdownSelector = props => {
  const currentValue = props.current || 50
  console.log('Currentvalue ' + currentValue)
  const [dropdowValue, setDropdownValue] = useState(currentValue)
  const handleChange = event => setDropdownValue(event.target.value)
  const loanHook = useLoanCompareMd()

  return (
    <Container>
      <Desc for="loancompare">
        <H3>Kuinka paljon haluat luottoa?</H3>
      </Desc>
      <Select onChange={handleChange} value={dropdowValue} id="loancompare">
        {loanHook.map(n => (
          <Option value={n.node.frontmatter.loanSum} key={n.node.id}>
            {n.node.frontmatter.loanSum}€
          </Option>
        ))}
      </Select>
      <Button external={false} url={`/vertaa/${dropdowValue}e`}>
        Vertaa luotot
      </Button>
      {props.sidebar ? (
        <div>
          Luottojen vertailun mahdollistaa{' '}
          <Link href="https://www.vippikingi.fi/">Vippikingi.fi</Link>
        </div>
      ) : (
        <div>
          Luotot sinulle vertaa{' '}
          <Link href="https://www.lainapallo.fi/">Lainapallo.fi</Link>
        </div>
      )}
    </Container>
  )
}

export default DropdownSelector
